export const en = {
  Login: "Login",
  Logout: "Logout",
  Translation: "Nederlands",
  Dashboard: "Dashboard",
  "Footer content": "Footer content",
  Link: "Link",
  Welcome: "Welcome",
  "Login with DigiD": "Login with DigiD",
  "Login with your account": "Login with your account",
  "Oops, something went wrong": "Oops, something went wrong",
  Username: "Username",
  Password: "Password",
  Send: "Send",
  Name: "Name",
  Active: "Active",
  "Last run": "Last run",
  "Next run": "Next run",
  "Date created": "Date created",
  "Date modified": "Date modified",
  Description: "Description",
  "Back to sources": "Back to sources",
  Objects: "Objects",
  Add: "Add",
  "Add Action": "Add Action",
  "Add Source": "Add Source",
  "Add Cronjob": "Add Cronjob",
  "Add Endpoint": "Add Endpoint",
  "Add Object": "Add Object",
  "Add Schema": "Add Schema",
  "Add Collection": "Add Collection",
  "Add User group": "Add User group",
  Attributes: "Attributes",
  ObjectEntities: "ObjectEntities",
  "Back to objects": "Back to objects",
  "Object detail page": "Object detail page",
  "Action handler": "Action handler",
  Handler: "Handler",
  Schema: "Schema",
  Gateway: "Source",
  "The minLength is bigger than the maxLength": "The minLength is bigger than the maxLength",
  "The minItems is bigger than the maxItems": "The minItems is bigger than the maxItems",
  "The minProperties is bigger than the maxProperties": "The minProperties is bigger than the maxProperties",
  "The minFileSize is bigger than the maxFileSize": "The minFileSize is bigger than the maxFileSize",
  "The minDate is bigger than the maxDate": "The minDate is bigger than the maxDate",
  "No properties found": "No properties found",
  "No logs found": "No logs found",
  "No objects found": "No objects found",
  "No plugins found": "No plugins found",
  "No schemas found": "No schemas found",
  "No actions found": "No actions found",
  "No sources found": "No sources found",
  "No conjobs found": "No conjobs found",
  "No endpoints found": "No endpoints found",
  "Search on name": "Search on name",
  "Update to": "Update to",
  "Enter search query": "Enter search query",
};
